<template>
  <div class="action">
    <div class="box" v-for="(item, index) in list" :key="index" @click="pose(index)">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: ["点赞", "鼓掌", "起立"],
    };
  },
  methods: {
    pose(key) {
      if (key == 0) {
        console.log("挥手");
        let str = "1056,gif,1";
        this.$chat.ws.send(str);
      }

      if (key == 1) {
        let str = "1056,gif,0";
        this.$chat.ws.send(str);
      }

      if (key == 2) {
        this.Bus.$emit("stand", "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  width: 66px;
  position: absolute;
  right: 18px;
  top: 90px;
  z-index: 10000;

  .box {
    width: 66px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
}
</style>
