<template>
  <div class="action">
    <div class="box" v-for="(item, index) in list" :key="index" @click="roam(index)">
      {{ item }}
    </div>
  </div>
</template>

<script>
import { check_code } from "../assets/api/index";
export default {
  data() {
    return {
      list: ["角色", "舞台视角", "大屏幕"],
    };
  },
  methods: {
    roam(key) {
      document.o2.switch_view(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  width: 66px;

  position: absolute;
  right: 86px;
  top: 90px;
  z-index: 10000;
  .box {
    width: 66px;
    height: 30px;
    background: #ffffff;
    border-radius: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
}
</style>
