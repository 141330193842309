<template>
  <div class="login">
    <div class="info">
      <!-- <div class="lang">
        <h3 style="margin-right: 20px">{{ $t("info.lang.language") }}</h3>
        <el-select v-model="lang" placeholder="请选择语言">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <el-tabs v-model="activeName" :stretch="true">
        <el-tab-pane :label="$t('info.lang.login')" name="login">
          <div style="width: 100%; height: 30px"></div>

          <div class="line">
            <h3>{{ $t("info.lang.account") }}</h3>
            <div class="input">
              <input :placeholder="$t('info.lang.accountInfo')" v-model="account" />
            </div>
          </div>
          <div class="line">
            <h3>{{ $t("info.lang.password") }}</h3>
            <div class="input">
              <input
                :placeholder="$t('info.lang.passwordInfo')"
                v-model="psw"
                type="password"
              />
            </div>
          </div>

          <div class="button">
            <el-button type="primary" @click="login">{{
              $t("info.lang.login")
            }}</el-button>

            <el-button type="info" @click="login1"> 游客登陆 </el-button>
          </div>

          <!-- <div class="button" style="text-align: right">
            <el-button type="info" @click="login1"> 游客登陆 </el-button>
          </div> -->
        </el-tab-pane>
        <el-tab-pane :label="$t('info.lang.register')" name="register"
          ><div style="width: 100%; height: 30px"></div>
          <div class="line">
            <h3>{{ $t("info.lang.account") }}</h3>
            <div class="input">
              <input :placeholder="$t('info.lang.accountInfo')" v-model="raccount" />
            </div>
          </div>
          <div class="line">
            <h3>{{ $t("info.lang.nickname") }}</h3>
            <div class="input">
              <input :placeholder="$t('info.lang.nicknameInfo')" v-model="nickname" />
            </div>
          </div>
          <div class="line">
            <h3>{{ $t("info.lang.password") }}</h3>
            <div class="input">
              <input
                :placeholder="$t('info.lang.passwordInfo')"
                v-model="rpsw"
                type="password"
              />
            </div>
          </div>
          <div class="line">
            <h3>{{ $t("info.lang.password") }}</h3>
            <div class="input">
              <input
                :placeholder="$t('info.lang.passwordInfo1')"
                v-model="cpsw"
                type="password"
              />
            </div>
          </div>

          <div class="button">
            <el-button type="primary" @click="register">{{
              $t("info.lang.register")
            }}</el-button>
          </div>
        </el-tab-pane>

        <!-- <el-tab-pane label="游客登录" name="visitor"
          ><div style="width: 100%; height: 30px"></div>

          <div class="line">
            <h3>{{ $t("info.lang.nickname") }}</h3>
            <div class="input">
              <input :placeholder="$t('info.lang.nicknameInfo')" v-model="nickname1" />
            </div>
          </div>

          <div class="button">
            <el-button type="primary" @click="login1">{{
              $t("info.lang.login")
            }}</el-button>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>

    <img src="../assets/newImg/banner.jpg" class="pic" />
  </div>
</template>

<script>
import { register, login, mail_send, mail_verify, save } from "../assets/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      type: "",
      account: "",
      psw: "",
      nickname: "",
      nickname1: "",
      raccount: "",
      rpsw: "",
      cpsw: "",
      email1: "",
      email2: "",
      email: "",
      emailcode: "",
      time: 30,
      timer: "",
      msg: "获取邮箱验证码",
      options: [
        {
          value: "zh",
          label: this.$t("info.lang.zh"),
        },
        {
          value: "en",
          label: this.$t("info.lang.en"),
        },
      ],
      lang: "zh",
      activeName: "login",
    };
  },
  methods: {
    reloadcode() {
      if (!this.email1 || !this.email2) {
        this.$message.error("请填写完整邮箱");
        return;
      }
      var email = `${this.email1}${this.email2}`;
      mail_send({ email }).then((res) => {
        this.$message(res);
        this.timmer = setInterval(() => {
          this.time--;
          this.msg = `还剩${this.time}s`;

          if (this.time == 0) {
            this.time = 30;
            clearInterval(this.timmer);
            this.msg = "获取邮箱验证码";
          }
        }, 1000);
      });
    },
    login() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      var reg2 = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

      if (!this.account || !this.psw) {
        this.$message.error(this.$t("info.lang.loginError1"));
        // } else if (!reg.test(this.account) && !reg2.test(this.account)) {
        //   this.$message.error(this.$t("info.lang.loginError5"));
        // }
      } else {
        login({
          acount: this.account,
          psd: md5(this.psw),
        }).then((res) => {
          if (res.code != 1) {
            this.$message({
              message: this.$t("info.lang.loginSuccess"),
              type: "success",
            });
            var obj = {
              acount: this.account,
              psd: md5(this.psw),
            };
            localStorage.setItem("info", JSON.stringify(obj));

            // if (res.info) {
            //   localStorage.setItem("info", res.info);
            // } else {
            //   var info = { name: res.name, sex: 1, accInfo: "0-0-0-1", id: res.name };
            //   localStorage.setItem("info", JSON.stringify(info));
            // }
            this.$router.push({ name: "mobileIndex" });
            document.o2.firstLogin = true;
          } else {
            this.$message.error(res.error);
          }
        });
      }
    },
    register() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      var reg1 = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      var reg2 = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

      if (!this.raccount || !this.rpsw || !this.nickname || !this.cpsw) {
        this.$message.error(this.$t("info.lang.loginError1"));
      } else if (!reg.test(this.raccount) && !reg2.test(this.raccount)) {
        this.$message.error(this.$t("info.lang.loginError5"));
        // } else if (this.psw != "123456") {
        //   this.$message.error(this.$t("info.lang.loginError2"));
      } else if (this.cpsw != this.rpsw) {
        this.$message.error(this.$t("info.lang.passwordInfo2"));
      } else if (!reg1.test(this.nickname)) {
        this.$message.error("昵称不能包含特殊符号");
      } else {
        register({
          name: this.nickname,
          acount: this.raccount,
          psd: md5(this.rpsw),
        }).then((res) => {
          if (res.code == 0) {
            save({
              name: this.nickname,
              info: JSON.stringify({
                email: this.email,
                type: this.type,
                name: this.nickname,
                sex: 1,
                accInfo: "0-0-0-1",
                avatar: 0,
                bodyColor: "#F66C47",
                hairColor: "#191919",
              }),
              auth: 2,
            }).then((res) => {
              if (res.code == 0) {
                this.account = this.raccount;
                this.activeName = "login";
              }
            });

            this.$message({
              message: res.info,
              type: "success",
            });
          } else {
            this.$message.error(res.info);
          }
        });
      }
    },

    login1() {
      this.vnum = this.getRandomSixNum();
      this.nickname1 = `游客${this.vnum}`;
      var obj = {
        acount: this.nickname1,
        type: true,
        name: this.nickname1,
      };

      localStorage.setItem("info", JSON.stringify(obj));
      this.$router.push({ name: "mobileIndex" });
      document.o2.firstLogin = true;
    },

    getRandomSixNum() {
      let RandomSixStr = "";
      for (let i = 0; i < 6; i++) {
        RandomSixStr += String(Math.floor(Math.random() * 10));
      }
      return RandomSixStr;
    },
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.options[0].label = this.$t("info.lang.zh");
      this.options[1].label = this.$t("info.lang.en");
      localStorage.setItem("locale", val);
    },

    activeName(val) {
      if (val == "visitor") {
        this.vnum = this.getRandomSixNum();
        this.nickname1 = `游客${this.vnum}`;
      }
    },
  },

  beforeCreate() {
    // console.log("加载开始");
    // if (localStorage.getItem("info")) {
    //   this.$router.push({ name: "mobileIndex" });
    // }
    // if (localStorage.getItem("token")) {
    //   this.$router.push({ name: "mobileIndex" });
    // }
  },

  created() {
    if (localStorage.getItem("locale")) {
      console.log(localStorage.getItem("locale"));
      this.lang = localStorage.getItem("locale");
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  .info {
    width: 367px;
    height: 100%;
    // background: rgba($color: #0e0e0e, $alpha: 0.1);
    // box-shadow: 3.94px 4.53px 21px 6px rgba(255, 43, 43, 0.1);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: scale(0.8);
    h1 {
      padding-top: 60px;
      font-size: 26px;
      font-weight: 800;
      color: white;
      margin-bottom: 44px;
    }
    .button {
      text-align: left;
      margin-top: 35px;
    }
    .lang {
      display: flex;
      position: fixed;
      bottom: 30px;
      align-items: center;
      color: white;
      right: 30px;
      ::v-deep.el-input__inner {
        background: transparent;
        color: white;
      }
    }

    .login3 {
      display: flex;
      position: fixed;
      bottom: 30px;
      align-items: center;
      color: white;
    }

    ::v-deep.el-tabs__item.is-active {
      color: #ff0000;
    }
    ::v-deep.el-tabs__item {
      color: white;
    }
    ::v-deep.el-tabs__active-bar {
      background-color: #ff0000;
    }
    .chooseType {
      .el-button {
        font-size: 20px;
        width: 70%;
        margin-bottom: 30px;
      }
      .el-button--danger {
        background-color: #ff0000;
        border-color: #ff0000;
      }
    }
  }
  .pic {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .el-button--primary {
    background-color: #ff0000;
    border-color: #ff0000;
    width: 48%;
  }

  .el-button--info {
    width: 48%;
  }
  .input1 {
    width: 49%;
    display: inline-block;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    h3 {
      font-size: 20px;
      color: white;
      text-align: left;
      margin-right: 12px;
      width: 100px;
    }
    .input {
      position: relative;
      flex: 1;

      input {
        background: transparent;
        -webkit-appearance: none;

        background-image: none;
        border-radius: 4px;
        border: 1px solid #1a1a1a;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        font-size: 12px;
      }

      .el-button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .toEmail {
    text-align: center;
    font-size: 14px;

    font-weight: 400;
    margin-top: 10px;
    color: #828282;
    cursor: pointer;
  }
}
a {
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .login {
    .info {
      width: 100%;
    }
    .pic {
      width: 0;
    }
  }
}
</style>
