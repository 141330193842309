import { get, post } from "./request";

export const register = (params) => get("/register.php", params); //获取素材
export const login = (params) => get("/login.php", params);
export const save = (params) => get("/save.php", params);

export const check_status = (params) => get("/check_status.php", params);//检查权限/change_status
export const change_status = (params) => get("/change_status.php", params);//检查权限/change_status

export const check_code = (params) => get("/check_code.php", params);//检查权限/check_code

export const client = (params) => get("/check_meetingroom.php", params);


export const register_user2 = (params) => get("/register_user2.php", params); //token注册
