<template>
  <div>
    <!-- 人物信息 -->

    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <Roam v-if="roamFlag" @close="roamFlag = false" />
    </transition>
    <!-- 右侧菜单 -->
    <div class="rightFuntion" v-if="sitFlag">
      <div
        v-for="(item, index) in rightFunction"
        :key="index"
        @click="fun(index)"
        style="margin-right: 20px"
      >
        <img :src="item.i" />
        <p>{{ item.f }}</p>
      </div>
    </div>

    <div class="leftFuntion">
      <!-- <div style="margin-right: 20px" @click="loginOut">
        <img src="../assets/newImg/mobile/function2.png" />
        <p>重新登录</p>
      </div> -->

      <div style="margin-right: 20px" @click="changeFlag = !changeFlag">
        <img src="../assets/newImg/mobile/function3.png" />
        <p>换装</p>
      </div>

      <div style="margin-right: 20px" @click="csflag = !csflag">
        <img src="../assets/newImg/舞台.png" style="width: 44px" />
        <p>传送门</p>
      </div>

      <!-- <div style="margin-right: 20px" @click="cs('舞台')">
        <img src="../assets/newImg/舞台.png" style="width: 44px" />
        <p>舞台</p>
      </div> -->
    </div>

    <!-- 传送 -->
    <div class="action1" v-if="csflag">
      <div class="box" @click="cs('观众座椅')">观众席</div>
      <div class="box" @click="cs('vip')" v-if="auth > 2">vip席</div>
      <div class="box" @click="cs('舞台')" v-if="auth > 2">舞台</div>
    </div>
    <!-- 换装 -->
    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <Change v-if="changeFlag" @close="changeFlag = false" />
    </transition>

    <!-- 动作 -->

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <Action v-if="actionFlag && sitFlag" />
    </transition>
  </div>
</template>

<script>
import Role from "./role.vue";
import Change from "./change.vue";
import Action from "./action.vue";
import Roam from "./roam.vue";
export default {
  data() {
    return {
      roleFlag: false,
      roamFlag: false,
      changeFlag: false,
      actionFlag: false,
      sitFlag: false,
      csflag: false,
      //右侧菜单
      rightFunction: [
        // {
        //   i: require("../assets/newImg/mobile/function2.png"),
        //   f: "重新登录",
        // },

        {
          i: require("../assets/newImg/mobile/function4.png"),
          f: "视角",
        },
        {
          i: require("../assets/newImg/mobile/function5.png"),
          f: "动作",
        },
      ],
    };
  },
  components: {
    Role,
    Change,
    Action,
    Roam,
  },
  methods: {
    fun(e) {
      console.log(e);

      // if (e == 0) {
      //   if (navigator.userAgent.match(/iPhone/i)) {
      //     this.$message.error("当前浏览器不支持全屏");
      //   }
      //   var el = document.documentElement;
      //   var isFullscreen =
      //     document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
      //   console.log(isFullscreen);
      //   if (!isFullscreen) {
      //     //进入全屏,多重短路表达式
      //     (el.requestFullscreen && el.requestFullscreen()) ||
      //       (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
      //       (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
      //       (el.msRequestFullscreen && el.msRequestFullscreen());
      //   } else {
      //     //退出全屏,三目运算符
      //     document.exitFullscreen
      //       ? document.exitFullscreen()
      //       : document.mozCancelFullScreen
      //       ? document.mozCancelFullScreen()
      //       : document.webkitExitFullscreen
      //       ? document.webkitExitFullscreen()
      //       : this.$message.error("当前浏览器不支持全屏");
      //   }
      // }
      // if (e == 1) {
      //   if (document.o2.role.state == "sit") {
      //     this.$message.error("坐下无法换装");
      //     return;
      //   }
      //   this.changeFlag = true;
      // }

      if (e == 0) {
        this.roamFlag = !this.roamFlag;
        this.actionFlag = false;
      }

      if (e == 1) {
        this.actionFlag = !this.actionFlag;
        this.roamFlag = false;
      }
    },
    loginOut() {
      this.$confirm(this.$t("info.lang.logOutInfo"))
        .then((_) => {
          localStorage.removeItem("info");
          location.reload();
          done();
        })
        .catch((_) => {});
    },

    cs(e) {
      if (e == "观众座椅") {
        document.o2.setRolePos(20350, 1692, -4319);
      } else if (e == "舞台") {
        document.o2.setRolePos(-42, 468, 5146);
      } else if (e == "vip") {
        document.o2.setRolePos(8518, -300, 2259);
      }
    },
  },

  created() {
    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });

    this.Bus.$on("loginS", (e) => {
      this.role = this.$chat.role;
      this.auth = this.$chat.role.auth;
    });
  },
};
</script>

<style lang="scss" scoped>
.function1 {
  position: absolute;
  top: 14px;
  left: 11px;
}

.rightFuntion,
.leftFuntion {
  top: 14px;
  right: 11px;
  display: flex;
  position: absolute;
  z-index: 10000;

  img {
  }

  p {
    width: 60px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    text-shadow: 0px 3px 4px 0px rgba(33, 58, 139, 0.92);
    text-align: center;
    margin-top: 5px;
  }
}
.leftFuntion {
  left: 20px;
  z-index: 1;
  width: 100px;
}
.rightFuntion {
  right: 0px;
}

.action1 {
  width: 66px;
  position: absolute;
  left: 96px;
  top: 90px;
  z-index: 10000;

  .box {
    width: 66px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }
}
</style>
