<template>
  <div>
    <div class="bg" v-if="!flag">
      <img src="../assets/newImg/main.png" class="main" />
      <div class="bgbtn" @click="none"></div>
    </div>
    <div class="loading" ref="load">
      <div style="width: 100%" v-if="flag">
        <div class="line">
          <div class="full" :style="'width:' + width + '%'"></div>
        </div>
        <div class="loadmsg">{{ loading_msg }}</div>
      </div>
      <div class="top" v-if="flag">
        <img src="../assets/newImg/oly.png" class="logo" />
        <div class="img1 move1"></div>
        <div class="img2 move2"></div>
        <div class="img3 move3"></div>
      </div>

      <div class="font">齐鲁晚报·齐鲁壹点</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //喷漆颜色列表

      loading_msg: "",
      width: 0,

      flag: true,
    };
  },
  methods: {
    none() {
      this.flag = true;
      // this.$refs.load.style.display = "none";
    },
  },

  created() {
    // var count = 0;

    // var timmer = setInterval(() => {
    //   count++;
    //   if (count == 5) {
    //     this.$message.error("小i飞速加载中,请稍后");
    //   }
    //   console.log(`加载${count}秒`);
    // }, 1000);
    document.o2vr.on_loading_msg = (a, b, c) => {
      console.log(a, b, c);
      this.loading_msg = `${b}${c}`;
      this.width = b;

      if (b == 100) {
        setTimeout(() => {
          // this.$refs.load.style.opacity = 0;
        }, 1000);
        setTimeout(() => {
          // clearInterval(timmer);
          // alert(`共加载${count}秒`);
          // this.$refs.load.style.display = "none";
          this.$refs.load.style.display = "none";
        }, 2000);
      }
    };
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.loading {
  position: absolute;
  width: 100%;
  color: white;
  height: 100%;
  background-color: black;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9998;
  font-size: 30px;
  opacity: 1;
  transition: 1s;
  background-size: 100% 100%;
}
.color {
  color: #21ebff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}

.light {
  color: #21ebff;

  bottom: 300px;
  left: 200px;
}

.btn:hover {
  background: #21ebff;
  color: white;
  box-shadow: 0 0 50px #21ebff;
  transition-delay: 0.5s;
  border-radius: 50%;
}

.btn:nth-child(1) {
  filter: hue-rotate(115deg);
}

.btn:nth-child(3) {
  filter: hue-rotate(270deg);
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid #21ebff;
  border-left: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.btn:hover::before {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}

.btn::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #21ebff;
  border-right: 2px solid #21ebff;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.btn:hover::after {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}

.choose {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.pie {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  list-style: none;
  overflow: hidden;
  transform: rotate(0deg); /*针对mac safari浏览器兼容*/
  opacity: 0.5;
  transition: 1s;
}

.slice {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.slice-1 {
  transform: rotate(60deg) skewY(-54deg);
  background: #47c6f0;
}
.slice-2 {
  transform: rotate(120deg) skewY(-54deg);
  background: #a2dceb;
}
.slice-3 {
  transform: rotate(180deg) skewY(-54deg);
  background: #40373a;
}
.slice-4 {
  transform: rotate(240deg) skewY(-54deg);
  background: #ac332e;
}
.slice-5 {
  transform: rotate(300deg) skewY(-54deg);
  background: #fff;
}
.slice-6 {
  transform: rotate(360deg) skewY(-54deg);
  background: #bdc3cf;
}

.animate {
  display: none;
  transition: All 2s ease-in-out;
  opacity: 0;
}
.line {
  display: inline-block;
  background: rgb(94, 95, 95);
  width: 40%;
  height: 20px;
  // border: 1px solid #ff0000;
  opacity: 0.5;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin-top: 25%;
}
.loadmsg {
  width: 100%;

  margin-top: 20px;
  color: rgb(94, 95, 95);
}
.full {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background-image: linear-gradient(to right, #33f07c, #4770ff);
  transition: 0.5;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body,
html {
  width: 100%;
  height: 100%;
  background: #000;
}

.wrap {
  width: 40%;
  height: 40%;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
}

.img1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/newImg/img1.png") no-repeat center center;
}

.img2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/newImg/img2.png") no-repeat center center;
}
.logo {
  width: 200px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.img3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/newImg/img3.png") no-repeat center center;
}

.move1 {
  animation: myMove1 5s ease-in infinite alternate;
  -webkit-animation: myMove1 5s ease-in infinite alternate;
}

.move2 {
  animation: myMove2 3s ease infinite alternate;
  -webkit-animation: myMove1 3s ease infinite alternate;
}

.move3 {
  animation: myMove3 2s ease-in infinite alternate;
  -webkit-animation: myMove1 2s ease-in infinite alternate;
}

.bg {
  width: 1145px;
  height: 805px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../assets/newImg/BG.png");
  z-index: 9999;
  background-size: 100% 100%;
  .main {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 90%;
    height: 90%;
  }
  .bgbtn {
    width: 370px;
    height: 50px;
    background: url("../assets/newImg/bt.png");
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 120px;
    cursor: pointer;
  }
}

@keyframes myMove1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes myMove1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes myMove2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes myMove2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes myMove3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes myMove3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 100%;
}

.font {
  width: 100%;
  font-size: 15px;
  color: rgb(94, 95, 95);
  text-align: center;
  position: fixed;
  bottom: 30px;
  font-family: "黑体";
}

@media screen and (max-width: 1000px) {
  .top {
    width: 30%;
    height: 30%;
    margin-top: 200px;
  }
  .logo {
    width: 120px;
    height: 120px;
  }
  .loadmsg {
    font-size: 18px;
    margin-top: 3%;
  }

  .line {
    width: 60%;
    height: 20px;
  }
}
</style>
