<template>
  <div class="back">
    <div class="info">
      <div class="banner">
        <img src="../assets/newImg/oly.png" />
      </div>

      <div class="login">
        <img src="../assets/newImg/title.png" class="title" />
        <div class="input">
          <input placeholder="请输入手机号" v-model="account" />
        </div>

        <div class="input">
          <input placeholder="请输入密码" v-model="psw" type="password" />
        </div>

        <div class="btn" @click="login">立即登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../assets/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      account: "",
      psw: "",
    };
  },

  methods: {
    login() {
      if (!this.account || !this.psw) {
        this.$message.error(this.$t("info.lang.loginError1"));
      } else {
        login({
          acount: this.account,
          psd: md5(this.psw),
        }).then((res) => {
          if (res.code != 1) {
            this.$message({
              message: this.$t("info.lang.loginSuccess"),
              type: "success",
            });
            var obj = {
              acount: this.account,
              psd: md5(this.psw),
            };
            localStorage.setItem("info", JSON.stringify(obj));
            this.$router.push({ name: "index" });
            document.o2.firstLogin = true;
          } else {
            this.$message.error(res.error);
          }
        });
      }
    },
  },

  created() {
    console.log("加载开始");
    if (localStorage.getItem("info")) {
      this.$router.push({ name: "index" });
    }
  },
};
</script>

<style lang="scss" scoped>
.back {
  background: rgb(20, 21, 22);
  width: 100%;
  height: 100%;

  .info {
    width: 1027px;
    height: 616px;
    background: #202022;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    .banner {
      width: 616px;
      height: 100%;
      border-right: 1px solid #eee;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .login {
      width: 410px;
    }

    .title {
      width: 181px;
      height: 40px;
      margin-top: 161px;
      margin-bottom: 60px;
    }
  }

  .input {
    padding: 0 60px;
    margin-bottom: 20px;
    input {
      background: transparent;
      -webkit-appearance: none;

      background-image: none;
      border-radius: 4px;
      border: 1px solid #000;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      font-size: 12px;
    }

    .el-button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .btn {
    margin: 0 60px;
    background: url("../assets/newImg/btn.png");
    height: 40px;
    background-size: 100% 100%;
    margin-top: 80px;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
