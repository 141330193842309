<template>
  <div style="position: absolute; left: 0; top: 0; width: 100%">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#262626"
      text-color="#fff"
      active-text-color="#fff"
    >
      <div src="" class="img"></div>

      <el-menu-item index="5" v-if="!type"
        ><img src="../assets/newImg/pf.png" class="icon1" />换装</el-menu-item
      >
      <el-submenu index="1" v-if="auth == 5">
        <template slot="title"
          ><img src="../assets/newImg/wz.png" class="icon1" />主持人</template
        >

        <!-- <el-submenu index="1-2">
          <template slot="title">播放动画</template>
          <el-menu-item index="1-2-1">动画一</el-menu-item>
          <el-menu-item index="1-2-2">动画二</el-menu-item>
        </el-submenu> -->

        <el-menu-item index="1-3">播放视频</el-menu-item>
        <!-- <el-menu-item index="1-4">同步相机</el-menu-item> -->
        <el-menu-item index="1-5">主持人管理</el-menu-item>
        <el-menu-item index="1-6">全体静音</el-menu-item>
      </el-submenu>

      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >
        <chose-role v-if="changeFlag" @close="closeFlag" />
      </transition>
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >
        <chose-role v-if="changeFlag" @close="closeFlag" />
      </transition>
      <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
      >
        <UserList :flag="userFlag" @close="closeFlag" v-if="userFlag" />
      </transition>
      <p class="loginOut" @click="loginOut">
        <img src="../assets/newImg/tc.png" class="icon1" />{{ $t("info.lang.logOut") }}
      </p>
    </el-menu>

    <div class="bottomfunction">
      <div v-for="(item, index) in btList" :key="index" @click="btfun(index)">
        <div v-if="index < 3">
          <img :src="item.img" />
          <p style="color: white">{{ item.text }}</p>
        </div>
        <div v-if="sitFlag && index > 2">
          <img :src="item.img" />
          <p style="color: white" v-if="index == 4">
            {{ screenFlag ? item.text1 : item.text }}
          </p>
          <p style="color: white" v-else>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import choseRole from "./choseRole.vue";
import choseMsg from "./choseMsg.vue";
import UserList from "./userList.vue";
import { save, check_code } from "../assets/api/index";
import Bus from "../../common/js/bus";
export default {
  components: { choseRole, choseMsg, UserList },
  data() {
    return {
      activeIndex: "99",
      changeFlag: false,
      msgFlag: false,
      roleList: [
        require("../assets/img/role1.jpg"),
        require("../assets/img/role2.jpg"),
        require("../assets/img/role3.png"),
        require("../assets/img/role4.png"),
        require("../assets/img/role5.jpg"),
        require("../assets/img/role6.jpg"),
      ],

      role: "",
      auth: 0,
      userFlag: false,
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      sitFlag: false,
      type: "",

      btList: [
        { img: require("../assets/newImg/截屏.png"), text: "截屏" },
        { img: require("../assets/newImg/点赞.png"), text: "点赞" },
        { img: require("../assets/newImg/鼓掌.png"), text: "鼓掌" },
        { img: require("../assets/newImg/起立.png"), text: "起立" },
        { img: require("../assets/newImg/聚焦.png"), text: "聚焦", text1: "返回" },
      ],
    };
  },
  methods: {
    closeFlag(e) {
      if (e) {
        this.msgFlag = e;
      } else {
        this.changeFlag = e;
        this.msgFlag = e;
        this.userFlag = e;
      }
    },

    changeAvatar(e) {
      console.log(e);
      this.role.avatar = e;
      this.$chat.role.avatar = e;
      save({
        name: this.$chat.role.name,
        info: JSON.stringify(this.$chat.role),
      }).then((res) => {});
      // localStorage.setItem("info", JSON.stringify(this.$chat.role));
    },
    handleSelect(key, keyPath) {
      if (keyPath[1] == "2-1") {
        let clip;
        if (key == "2-1-1") {
          clip = "Greeting";
        } else if (document.o2.role.sit) {
          clip = "clap";
        }
        if (clip) {
          document.o2.role.playClip(clip);
          let msg = 1056 + ",";
          let info = { msg: "playClip", data: clip };
          msg += JSON.stringify(info);
          this.$chat.ws.send(msg);
        }
        console.log("动作", key);
        if (key == "3-1-2") {
          let str = "1056,gif,0";
          this.$chat.ws.send(str);
          //document.o2.gif_pop(document.o2.player,0);
        }
        if (key == "3-1-4") {
          let str = "1056,gif,1";
          this.$chat.ws.send(str);
        }
      }

      if (keyPath[1] == "3-2") {
        this.jushou();
      }

      if (keyPath[1] == "4-1") {
        this.zipai();
      }

      if (keyPath[1] == "3-3") {
        this.qili();
      }

      if (keyPath[1] == "1-2") {
        if (key == "1-2-1") {
          document.o2.play_animation(0);
        }
        if (key == "1-2-2") {
          document.o2.play_animation(1);
        }
        console.log("动画", key);
      }

      if (keyPath[1] == "1-3") {
        this.$prompt("请输入播放视频链接", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          document.o2.screen_url(value);
        });
      }

      if (keyPath[1] == "1-6") {
        let data = { msg: "allmute", data: 0 };
        this.$chat.ws.send("1056," + JSON.stringify(data));
      }

      if (keyPath[2] == "2-2-1") {
        this.open(1);
      }

      if (keyPath[2] == "2-2-2") {
        this.open(2);
      }

      if (keyPath[2] == "2-2-3") {
        this.open(3);
      }

      if (keyPath[1] == "1-4") {
        console.log("相机同步");
      }

      if (keyPath[1] == "4-3") {
        this.loginOut();
      }
      if (keyPath[1] == "1-5") {
        this.userFlag = !this.userFlag;
      }

      if (keyPath[2] == "3-4-1") {
        console.log("第三人称视角");
        document.o2.switch_view(0);
      }
      if (keyPath[2] == "3-4-2") {
        console.log("展台视角");
        document.o2.switch_view(1);
      }
      if (keyPath[2] == "3-4-3") {
        console.log("大屏幕视角");
        document.o2.switch_view(2);
      }

      if (keyPath[0] == "5") {
        if (document.o2.role.state == "sit") {
          this.$message.error("坐下无法换装");
          return;
        }
        this.changeFlag = !this.changeFlag;
        this.msgFlag = false;
      }
    },

    zipai() {
      var date = new Date().getTime();
      // var name = `自拍${date}`;
      this.compress(document.o2.shot(), 1920, 1080).then((res) => {
        this.$notify({
          title: "成功",
          message: "自拍成功",
          type: "success",
        });
      });
    },

    compress(base64String, w, quality) {
      let a = document.createElement("a");

      a.style.display = "none";
      var that = this;
      var getMimeType = function (urlData) {
        var arr = urlData.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        // return mime.replace("image/", "");
        return mime;
      };
      var newImage = new Image();
      var imgWidth, imgHeight;

      var promise = new Promise((resolve) => (newImage.onload = resolve));
      newImage.src = base64String;

      return promise.then(() => {
        imgWidth = newImage.width;
        imgHeight = newImage.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = (w * imgHeight) / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = (w * imgWidth) / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);

        let imgobj = new Image();
        imgobj.src = require("../assets/newImg/banner1.png");

        imgobj.onload = function () {
          ctx.drawImage(
            imgobj,
            canvas.width - 370 / 2 - 20,
            canvas.height - 160 / 2 - 20,
            370 / 2,
            160 / 2
          );

          let imgobj1 = new Image();
          imgobj1.src = require("../assets/newImg/banner2.png");

          imgobj1.onload = function () {
            ctx.drawImage(imgobj1, 20, canvas.height - 834 / 5 + 20, 834 / 5, 834 / 5);
            var base64 = canvas.toDataURL(getMimeType(base64String), quality);
            var blob = that.dataURLToBlob(base64);
            a.setAttribute("href", URL.createObjectURL(blob));
            a.setAttribute("download", "自拍.png");
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(blob);
            document.body.removeChild(a);
          };
        };

        // return base64;
      });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    getStatus(e) {
      if (e.time0 == null) {
        return 0; //空闲
      }
      var time = new Date().getTime();

      if (time > Number(e.time0) && time < Number(e.time1)) {
        return 1; //正在使用
      }

      if (time < e.time1) {
        return 2; //已预约
      }

      if (time > e.time1) {
        return 3;
        //已过期;
      }
    },

    qili() {
      this.Bus.$emit("stand", "");
    },
    jushou() {
      let role = document.o2.role;
      if (role && role.sit) {
        let cmd = role.bHand ? "handDown" : "handUp";
        role.bHand = !role.bHand;
        let data = { msg: cmd, data: role.sit };
        this.$chat.ws.send("1056," + JSON.stringify(data));
      }
    },
    pose(key) {
      let clipName = "";
      if (key == "3-1") {
        if (document.o2.role.state != "idle") {
          this.$message.error("该动作只有站立才能使用");
          return;
        }
        console.log("动作1");
        clipName = "站立招手";
      }
      if (key == "3-2") {
        if (document.o2.role.state != "idle") {
          this.$message.error("该动作只有站立才能使用");
          return;
        }
        clipName = "站立挥手";
      }
      if (key == "3-3") {
        if (document.o2.role.state != "sit") {
          this.$message.error("该动作只有坐下才能使用");
          return;
        }
        clipName = "坐着鼓掌";
      }

      if (clipName && clipName != "") {
        document.o2.role.playClip(clipName, false, false);
        let msg = "playAnimationClip,";
        msg += document.o2.role.name;
        msg += "," + clipName;
        this.$chat.ws.send(msg);
      }
    },

    loginOut() {
      this.$confirm(this.$t("info.lang.logOutInfo"))
        .then((_) => {
          localStorage.removeItem("info");
          location.reload();
          done();
        })
        .catch((_) => {});
    },

    btfun(e) {
      if (e == 0) {
        this.zipai();
      } else if (e == 1) {
        let str = "1056,gif,1";
        this.$chat.ws.send(str);
      } else if (e == 2) {
        let str = "1056,gif,0";
        this.$chat.ws.send(str);
      } else if (e == 3) {
        this.qili();
      } else if (e == 4) {
        this.screenFlag = !this.screenFlag;
        if (this.screenFlag) {
          document.o2.switch_view(2);
          this.text = "返回";
        } else {
          document.o2.switch_view(0);
          this.text = "聚焦";
        }
      }
    },
  },
  created() {
    this.Bus.$on("loginS", (e) => {
      this.role = this.$chat.role;
      if (!this.role.avatar) {
        this.role.avatar = 0;
      }
      this.auth = this.$chat.role.auth;
      this.type = this.$chat.role.type;
    });

    this.Bus.$on("renzheng", () => {
      this.msgFlag = true;
    });

    this.Bus.$on("loginS", () => {
      this.auth = this.$chat.role.auth;
    });

    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.img {
  width: 40px;
  float: left;
  margin-right: 20px;
  // margin-top: 10px;
  margin-left: 20px;
  border-radius: 50%;
  cursor: pointer;
  height: 60px;
}
.loginOut {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  float: right;
  margin-top: 18px;
  display: flex;
}
.bottomfunction {
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transform: scale(0.8);
}

.icon1 {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
</style>
