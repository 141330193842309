<template>
  <div class="index">
    <MainView />
    <Function />
    <O2vr />
    <Play :floor="'Rectangle294579029'" :ios="flag" />
    <Client />
    <!-- <Box :touch="flag" /> -->
    <O2im />
    <Box />
    <Zhuchiren />
    <!-- <div class="test">
      <img @click="zipai" src="../assets/newImg/camera.png" />
      <img @click="jushou" src="../assets/newImg/jushou.png" v-if="jushouFlag" />
      <img @click="qili" src="../assets/newImg/qili.png" v-if="sitFlag" />
    </div> -->
    <!-- <div class="shadow1" v-if="val > 0" @click="val--">
      <div
        class="box"
        style="left: 0; right: 0; top: 0; bottom: 0; margin: auto"
        v-if="val == 5"
      >
        <img src="../assets/newImg/info/wasd.png" />
        <p style="margin-top: 20px"></p>
      </div>
      <div class="box1" v-if="val == 4">
        <img src="../assets/newImg/info/shu1.png" />
      </div>
      <div class="box" style="left: 370px; top: 20px" v-if="val == 3">
        <img src="../assets/newImg/info/left1.png" />
        <p style="margin-top: 20px">
          点击这里去你想去的区域吧！/Click here to go to the area you want to go!
        </p>
      </div>

      <div class="box" style="left: 200px; top: 0px" v-if="val == 2">
        <img src="../assets/newImg/info/left.png" />
        <p style="margin-top: 20px">
          在此处按照您的喜好生成您的人物形象/ 在此处按照您的喜好生成您的人物形象 Generate
          your character here according to your preference
        </p>
      </div>

      <div class="box" style="left: 0; right: 0; top: 20px; margin: auto" v-if="val == 1">
        <p style="margin-top: 20px">
          进入范围区域时，会提示加入语音区间，可与范围内用户音视频通话。 When you enter
          the range area, you will be prompted to join the voice section, and you can make
          audio and video calls with users within the range.
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import MainView from "../components/MainView.vue";
import Function from "../components/function.vue";
import O2vr from "../components/loading.vue";
import Play from "../components/play_o2report.vue";
import O2im from "../components/o2Im.vue";
import Client from "../components/client.vue";
import Box from "../components/box.vue";
import Zhuchiren from "../components/zhuchiren.vue";
export default {
  data() {
    return {
      flag: false,
      sitFlag: false,
      jushouFlag: false,
      val: 0,
    };
  },
  components: { MainView, O2vr, Function, Play, O2im, Client, Box, Zhuchiren },
  methods: {
    qili() {
      this.Bus.$emit("stand", "");
    },
    jushou() {
      console.log("举手");
      if (document.o2.role && document.o2.role.sit) {
        let role = document.o2.role;
        if (role.bHand == undefined) role.bHand = false;
        let msg = role.bHand ? "handDown" : "handUp";
        role.bHand = !role.bHand;
        let info = { msg: msg, data: role.sit };
        let str = 1056 + "," + JSON.stringify(info);
        this.$chat.ws.send(str);
      }
    },
    zipai() {
      console.log("zipai");
      var date = new Date().getTime();
      // var name = `自拍${date}`;
      this.compress(document.o2.shot(), 1080, 1080).then((res) => {
        this.$notify({
          title: "成功",
          message: "自拍成功",
          type: "success",
        });
      });
    },

    compress(base64String, w, quality) {
      let a = document.createElement("a");

      a.style.display = "none";
      var that = this;
      var getMimeType = function (urlData) {
        var arr = urlData.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        // return mime.replace("image/", "");
        return mime;
      };
      var newImage = new Image();
      var imgWidth, imgHeight;

      var promise = new Promise((resolve) => (newImage.onload = resolve));
      newImage.src = base64String;

      return promise.then(() => {
        imgWidth = newImage.width;
        imgHeight = newImage.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = (w * imgHeight) / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = (w * imgWidth) / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);

        let imgobj = new Image();
        imgobj.src = require("../assets/newImg/banner1.png");
        imgobj.onload = function () {
          ctx.drawImage(
            imgobj,
            canvas.width - 691 / 4 - 20,
            canvas.height - 173 / 4 - 20,
            691 / 4,
            173 / 4
          );
          var base64 = canvas.toDataURL(getMimeType(base64String), quality);
          var blob = that.dataURLToBlob(base64);
          a.setAttribute("href", URL.createObjectURL(blob));
          a.setAttribute("download", "自拍.png");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(blob);
          document.body.removeChild(a);
        };

        // return base64;
      });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.flag = true;
    }
    this.Bus.$on("loginError4", () => {
      this.$message.error(this.$t("info.lang.loginError4"));
      setTimeout(() => {
        localStorage.removeItem("info");
        location.reload();
      }, 500);
    });
    // console.log(this.$o2Function.createDrag());
  },
  mounted() {
    this.Bus.$on("onRoleLoaded", (role) => {
      role.onStateChg.push((state) => {
        if (state == "sit") {
          this.sitFlag = true;
        } else {
          this.sitFlag = false;
        }
      });
    });
    if (document.o2.firstLogin == true) {
      this.val = 5;
    }
  },
  beforeCreate() {
    // if (!localStorage.getItem("info")) {
    //   this.$router.push({ name: "login" });
    // }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
}
.test {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 9999;
  img {
    width: 50px;
    margin: 0px 10px;
    cursor: pointer;
  }
}
.shadow1 {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #030303, $alpha: 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  .box {
    width: 270px;
    font-size: 18px;

    font-weight: 400;
    text-align: left;
    position: fixed;
    color: #fff;
    height: 220px;
  }
  .box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      margin: 0 60px;
    }
  }
}
</style>
